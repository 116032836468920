import { ContentTools } from "../../../document/states/persistent/content";
import { ContentTypes } from "../../../document/states/persistent/content";
import * as Types from './types';


class CellMarkersRepo {
  private _repo: Types.Repo;

  constructor() {
    this._repo = {};
  }

  addMarker(
    markerAddr: ContentTypes.MarkerAddr,
    markerElement: HTMLDivElement,
  ) {
    const markerKey = ContentTools.getMarkerKey(markerAddr);

    if ( markerKey in this._repo ) {
      const msg = `Marker already in repo`;
      throw new Error(msg);
    }

    const repoItem: Types.RepoItem = {
      element: markerElement
    }

    this._repo[markerKey] = repoItem;
  }

  removeMarker(
    markerAddr: ContentTypes.MarkerAddr,
  ) {
    const markerKey = ContentTools.getMarkerKey(markerAddr);

    if ( markerKey in this._repo === false ) {
      const msg = `Marker not in repo`;
      throw new Error(msg);
    }

    delete this._repo[markerKey];
  }

  getMarker(markerAddr: ContentTypes.MarkerAddr) {
    const markerKey = ContentTools.getMarkerKey(markerAddr);

    if ( ! (markerKey in this._repo) ) {
      const msg = `Marker not in repo`;
      throw new Error(msg);
    }

    const repoItem = this._repo[markerKey];
    return repoItem;
  }
}


export default CellMarkersRepo;