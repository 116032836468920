import { useEffect }   from "react";
import { useRef }      from "react";
import { useState }    from "react";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useIsMobile } from "lego-hooks/use-is-mobile";


import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  RangeSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_CRITICAL,
  ParagraphNode,
  LexicalNode,
  $isElementNode,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  $createParagraphNode,
  LexicalEditor,
  ElementNode,
} from "lexical";


import { 
  $patchStyleText, $setBlocksType,
} from "@lexical/selection";

import { 
  $getNearestNodeOfType, 
  mergeRegister 
} from "@lexical/utils";

import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode,
  ListItemNode,
  $isListItemNode
} from "@lexical/list";

import ControlsComponent from "./controls";
import { $getSelectedTextNodesCssPropertyNormalized } from "./lexical-hacks";
import { $processSelectedNodes } from "./lexical-hacks";

import { EditorTextDefaults }        from "app/ui/components/editor-txt";
import { SMART_INDENT_COMMAND }      from "app/ui/components/editor-txt/plugins/smart-ident-plugin/SmartIdentPlugin";
import { SMART_OUTDENT_COMMAND }     from "app/ui/components/editor-txt/plugins/smart-ident-plugin/SmartIdentPlugin";



interface Props {
  lexical: LexicalEditor;
}

enum TextAlign {
  NONE   = 'none',
  LEFT   = 'left',
  CENTER = 'center',
  RIGHT  = 'right',
};


export const LexicalToolbarComponent: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile() as boolean;
  const DROPDOWN_PANEL_HIDE_DELAY = (
    isMobile ?
    3500 :
    2500
  );
  
  const lexical = props.lexical;
  const FontDefaults = EditorTextDefaults.getFont();

  const selectionRef = useRef<any | null>(null);
  const [selectionIsBold,   setSelectionIsBold  ] = useState(false);
  const [selectionIsItalic, setSelectionIsItalic] = useState(false);
  const [fontColor,   setFontColor]   = useState(FontDefaults.color);
  const [fontBgColor, setFontBgColor] = useState(FontDefaults.background);
  const [fontSize,    setFontSize]    = useState<number | null>(FontDefaults.size.init);
  
  const [fontColorPanelVisible, setFontColorPanelVisible] = useState(false);
  const [fontBgColorPanelVisible, setFontBgColorPanelVisible] = useState(false);

  const [stylesPanelVisible, setStylesPanelVisible] = useState(false);
  const [fontSizePanelVisible, setFontSizePanelVisible] = useState(false);
  const [selectionListType, setSelectionListType] = useState<string | null>(null);
  const [selectionTextAlign, setSelectionTextAlign] = useState<TextAlign>(TextAlign.NONE);

  const processSelectionTextNodesCss = (selection: RangeSelection) => {
    setSelectionIsBold(selection.hasFormat('bold'));
    setSelectionIsItalic(selection.hasFormat('italic'));

    // Alternativly i could read css 
    // from the selection as selection
    // has style property, which already
    // combines all of styles from nodes.
    setFontColor($getSelectedTextNodesCssPropertyNormalized(selection, 'color', 'transparent', true)!);
    setFontBgColor($getSelectedTextNodesCssPropertyNormalized(selection, 'background-color', 'transparent', true)!);
    const fontSizeCss = $getSelectedTextNodesCssPropertyNormalized(selection, 'font-size', `${FontDefaults.size.init}px`, false);
    if (fontSizeCss === null ) {
      setFontSize(null);
    }
    else {
      const fontSizeValue = parseInt(fontSizeCss);
      if ( ! isNaN(fontSizeValue)) {
        setFontSize(fontSizeValue);
      }
      else {
        // Not sure what to do here
        // maybe setup null...
        setFontSize(FontDefaults.size.init);
      }
    }
  }

  const procssSelectionNodes = (selection: RangeSelection) => {
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      //-------------------------
      //
      // Single node selected
      //

      // if ($isTextNode(anchorNode) ) {
      // }

      //
      // ListItem node
      const listItem = $getNearestNodeOfType<ListItemNode>(
        anchorNode,
        ListItemNode,
      );
      if (listItem) {
        setSelectionTextAlign(listItem.getFormatType() as TextAlign);
      }

      //
      // List node
      const list = $getNearestNodeOfType<ListNode>(
        anchorNode,
        ListNode,
      );
      if (list) {
        setSelectionListType(list.getListType());
      }
      else {
        setSelectionListType(null);
      }

      //
      // Paragraph node
      const parentParagraph = $getNearestNodeOfType<ParagraphNode>(
        anchorNode,
        ParagraphNode,
      );
      if (parentParagraph) {
        if (parentParagraph.getFormatType() === '') {
          setSelectionTextAlign(TextAlign.NONE);
        }
        else {
          setSelectionTextAlign(parentParagraph.getFormatType() as TextAlign);
        }
      }
    }
    else {
      //-------------------------
      //
      // Multiple nodes selected
      //
      {
        const elementTextAlign = $processSelectedNodes(
          selection, 
          (node: LexicalNode) => $isElementNode(node),
          (node: LexicalNode) => {
            const element = node as ElementNode;
            return element.getFormatType();
          }
        );
        const areStylesSame = elementTextAlign.every(val => val === elementTextAlign[0]);
        if (areStylesSame) {
          setSelectionTextAlign(elementTextAlign[0] as TextAlign);
        }
        else {
          setSelectionTextAlign(TextAlign.NONE);
        }
      }

      {
        const listNodes = $processSelectedNodes(
          selection, 
          (node: LexicalNode) => true,
          (node: LexicalNode) => {
            const parentList = $getNearestNodeOfType<ListNode>(
              node,
              ListNode,
            );
            if (parentList) {
              return (parentList.getListType());
            }
            else {
              return ''
            }
          }
        );
        
        // console.log(listNodes)
        const areStylesSame = listNodes.every(val => val === listNodes[0]);
        if (areStylesSame) {
          setSelectionListType(listNodes[0] as string);
        }
        else {
          setSelectionListType(null);
        }
      }
    }
  }


  const updateToolbar = useCallback(
  () => {
    if (skipSelectionUpdateRef.current) {
      skipSelectionUpdateRef.current = false;
      return false;
    }

    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      processSelectionTextNodesCss(selection);
      procssSelectionNodes(selection);
    }

    return false;
  }, []);


  // The reason for skip selection update is that
  // when you have no text selected, but there
  // is a text node before cursor, then when you
  // change font color, update toolbar will kick
  // in and it will read the node before cursor
  // with diffrent font color value, comparing to
  // the one just set, and it will trigger toolbar
  // update with new font color. Therefore
  // in situation like this we skip toolbar update.
  // There is the same problem with background color
  // and similar problem with font size.
  const skipSelectionUpdateRef = useRef<boolean>(false);
  const skipSelectionUpdate = () => {
    skipSelectionUpdateRef.current = true;
  };



  useEffect(() => {
    return mergeRegister(
      lexical.registerUpdateListener(({ editorState }: any) => {
        editorState.read(() => {
          selectionRef.current = $getSelection() ;
          // updateToolbar();
          // causes problem on changing font size from drop down
          // because we change the font size, but nothing
          // parseable yet has been changed in editor content
          // therefore when it kick in, it will set font size 
          // (during updateToolbar) to old value.
          //
          // Do i need updateToolbar here?
        });
      }),
      lexical.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [lexical, updateToolbar]);


  useEffect(() => {
    lexical.getEditorState().read(() => {
      // console.log("first render called ")
      updateToolbar();
    });
  }, []);

  //--------------------------
  // Format paragraph

  const formatIndent = () => {
    hideAllPanels();
    lexical.dispatchCommand(SMART_INDENT_COMMAND, '');
  }
  const formatOutdent = () => {
    hideAllPanels();
    lexical.dispatchCommand(SMART_OUTDENT_COMMAND, '');
  }

  const formatNumberedList = () => {
    hideAllPanels();
    if (isSelectionNumberList()) {
      lexical.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setSelectionListType(null);
    }
    else {
      lexical.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setSelectionListType('number');
    }
  }

  const formatBulletList = () => {
    hideAllPanels();
    if (isSelectionBulletList()) {
      lexical.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setSelectionListType(null);
    }
    else {
      lexical.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setSelectionListType('bullet');
    }
  }


  //--------------------------
  // Format text

  const formatBold = () => {
    hideAllPanels();
    lexical.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
    setSelectionIsBold(! selectionIsBold);
  }

  const formatItalic = () => {
    hideAllPanels();
    lexical.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
    setSelectionIsItalic(! selectionIsItalic);
  }

  //--------------------------
  // Align

  const formatLeft = () => {
    hideAllPanels();
    if (selectionTextAlign === TextAlign.LEFT) {
      lexical.dispatchCommand(FORMAT_ELEMENT_COMMAND, "");
      setSelectionTextAlign(TextAlign.NONE);
    }
    else {
      lexical.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
      setSelectionTextAlign(TextAlign.LEFT);
    }
  }

  const formatCenter = () => {
    hideAllPanels();
    if (selectionTextAlign === TextAlign.CENTER) {
      lexical.dispatchCommand(FORMAT_ELEMENT_COMMAND, "");
      setSelectionTextAlign(TextAlign.NONE);
    }
    else {
      lexical.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
      setSelectionTextAlign(TextAlign.CENTER);
    }
  }

  const formatRight = () => {
    hideAllPanels();
    if (selectionTextAlign === TextAlign.RIGHT) {
      lexical.dispatchCommand(FORMAT_ELEMENT_COMMAND, "");
      setSelectionTextAlign(TextAlign.NONE);
    }
    else {
      lexical.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
      setSelectionTextAlign(TextAlign.RIGHT);
    }
  }

  //----------------------------
  // Text size


  const setTextSize = (size: number) => {
    hideAllPanels();
    setFontSize(size);
    applyStyleText({'font-size': `${size}px`});
  }

  //----------------------------
  // Text color

  const applyStyleText = 
    useCallback((styles: Record<string, string>) => {
      lexical.update(() => {
        const selection = $getSelection();
        if (selection === null) {
          return;
        }

        if ( ! $isRangeSelection(selection)) {
          return;
        }

        if ( selection.isCollapsed() ) {
          // return;
          skipSelectionUpdate()
          // In this case we should actually skip
          // patch-ing, as selection is collapsed
          // it means there is no content to patch.
          // However patch-ing will trigger
          // refocusing on ediotr.
        }

        $patchStyleText(selection, styles);
      }); // lexical.update
    }, [lexical]);
  

  const hideFontColorPanelDeb = useDebouncedCallback(() => {
    setFontColorPanelVisible(false);
  }, DROPDOWN_PANEL_HIDE_DELAY);

  const hideFontBgColorPanelDeb = useDebouncedCallback(() => {
    setFontBgColorPanelVisible(false);
  }, DROPDOWN_PANEL_HIDE_DELAY);

  
  const setTextColor = (color: string) => {
    applyStyleText({color: color});
    setFontColor(color);
  }

  const setTextBackgroundColor = (color: string) => {
    applyStyleText({'background-color': color});
    setFontBgColor(color);
  }

  const toggleFontColorPanelVisible = () => {
    hideAllPanels();
    setFontColorPanelVisible(! fontColorPanelVisible);
  }
  
  const toggleFontBgColorPanelVisible = () => {
    hideAllPanels();
    setFontBgColorPanelVisible(! fontBgColorPanelVisible);
  }

  const toggleStylesPanelVisible = () => {
    hideAllPanels();
    setStylesPanelVisible(! stylesPanelVisible);
  }

  const toggleFontSizePanelVisible = () => {
    hideAllPanels();
    setFontSizePanelVisible(! fontSizePanelVisible);
  }

  const hideAllPanels = () => {
    setFontColorPanelVisible(false);
    setFontBgColorPanelVisible(false);
    setStylesPanelVisible(false);
    setFontSizePanelVisible(false);
  }

  const isSelectionBulletList = () => {
    return selectionListType === 'bullet'; 
  }

  const isSelectionNumberList = () => {
    return selectionListType === 'number'; 
  }

  //----------------------------
  //
  const test = () => {
    lexical.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $processSelectedNodes(selection,
          (node: LexicalNode) => $isListItemNode(node),
          (node: LexicalNode) => {
            const element = node as ElementNode;
            element.setIndent(element.getIndent() + 1);
            return 2;
          }
        )
        
      }
    });
  }

  const test2 = () => {
    lexical.update(() => {
      // lexical.dispatchCommand(INSERT_SMART_LIST_COMMAND, '');

      // const selection = $getSelection();
      // if ($isRangeSelection(selection)) {
      // }
    });
  }

  const callbacks = {
    skipSelectionUpdate,

    formatNumberedList,
    formatBulletList,

    formatBold,
    formatItalic,

    formatLeft,
    formatCenter,
    formatRight,

    formatIndent,
    formatOutdent,

    setTextColor,
    setTextBackgroundColor,   
    
    toggleFontColorPanelVisible,
    toggleFontBgColorPanelVisible,

    toggleStylesPanelVisible,
    toggleFontSizePanelVisible,

    setTextSize,

    hideFontColorPanelDeb,
    hideFontBgColorPanelDeb,

    test,
    test2,
  };
  const states = {
    selectionIsBulletList: isSelectionBulletList(),
    selectionIsNumberList: isSelectionNumberList(),
    
    selectionTextAlignLeft:   selectionTextAlign === TextAlign.LEFT,
    selectionTextAlignRight:  selectionTextAlign === TextAlign.RIGHT,
    selectionTextAlignCenter: selectionTextAlign === TextAlign.CENTER,

    selectionIsBold,
    selectionIsItalic,

    fontColor,
    fontBgColor,

    fontColorPanelVisible,
    fontBgColorPanelVisible,

    stylesPanelVisible,
    fontSizePanelVisible,
    fontSize,
  };


  return (
    <ControlsComponent
      callbacks={callbacks}
      states={states}
    />
  );
}

