import { gql } from '@apollo/client';


export namespace mutation {
  export const updateUserSettings = gql`
    mutation UserSettingsUpdateMutation($settings: String!) {
      userSettingsUpdate(settings: $settings) {
        ok
      }
    }
  `;

  export const getJwtToken = gql`
    mutation TokenGet($email: String!, $password: String!) {
      tokenGet(email: $email, password: $password) {
        payload
        token
        refreshExpiresIn
      }
    }
  `;

  export const refreshJwtToken = gql`
    mutation TokenRefresh($token: String!) {
      tokenRefresh(token: $token) {
        payload
        token
        refreshExpiresIn
      }
    }
  `;

  export const loginUserGoogle = gql`
    mutation UserGoogleLogin($googleToken: String!) {
      userGoogleLogin(googleToken: $googleToken) {
        ok
        token
      }
    }
  `;

  export const createInstruction = gql`
    mutation InstructionCreateMutation($content: String, $repoId: Int!) {
      instructionCreate(content: $content, repoId: $repoId) {

        ... on Success {
          featureLimited
          instruction {
            id
          }
        }

        ... on FeatureLimited {
          featureLimited
          limitType
        }

      }
    }
  `;

  export const createInstructionDemo1 = gql`
  mutation InstructionCreateDemo1Mutation($repoId: Int!) {
    instructionCreateDemo1(repoId: $repoId) {

      ... on Success {
        featureLimited
        instruction {
          id
        }
      }

      ... on FeatureLimited {
        featureLimited
        limitType
      }

    }
  }
`;

  export const updateInstruction = gql`
    mutation InstructionUpdateMutation($id: Int!, $content: String!) {
      instructionUpdate(id: $id, content: $content) {
        ok
      }
    }
  `;

  export const deleteInstruction = gql`
    mutation InstructionDeleteMutation($id: Int!) {
      instructionDelete(id: $id) {
        ok
        id
      }
    }
  `;

  export const deleteInstructionImage = gql`
    mutation InstructionImageDeleteMutation($id: Int!) {
      instructionImageDelete(id: $id) {
        ok
        imageId
      }
    }
  `;

  export const deleteInstructionLogo = gql`
    mutation InstructionLogoDeleteMutation($logoId: Int!) {
      instructionLogoDelete(logoId: $logoId) {
        ok
      }
    }
  `;

  export const previewInstructionRelease = gql`
    mutation InstructionReleasePreviewMutation(
      $instructionId: Int!, 
      $content: String!, 
      $releaseInfo: String!
    ) {
      instructionReleasePreview(
        instructionId: $instructionId, 
        content: $content, 
        releaseInfo: $releaseInfo
      ) {
        ok
        instructionId
        revisionReleased
      }
    }
  `;

  export const createInstructionRelease = gql`
    mutation InstructionReleaseCreateMutation(
      $instructionId: Int!, 
      $content: String!,
      $releaseInfo: String!
    ) {
      instructionReleaseCreate(
        instructionId: $instructionId, 
        content: $content,
        releaseInfo: $releaseInfo
      ) {
        ok
        instructionId
        releaseDate
        revisionReleased
        revisionNext
      }
    }
  `;

  export const createWidgetStyle = gql`
    mutation WidgetStyleCreateMutation($instructionId: Int!, $name: String!, $style: String!) {
      widgetStyleCreate(instructionId: $instructionId, name: $name, style: $style) {
        ok
        widgetStyle {
          id
          name
          style
        }
      }
    }
  `;

  export const updateWidgetStyle = gql`
    mutation WidgetStyleUpdateMutation($id: Int!, $name: String, $style: String) {
      widgetStyleUpdate(id: $id, name: $name, style: $style) {
        ok
        widgetStyle {
          id
          name
        }
      }
    }
  `;

  export const deleteWidgetStyle = gql`
    mutation WidgetStyleDeleteMutation($id: Int!) {
      widgetStyleDelete(id: $id) {
        ok
        id
      }
    }
  `;

  export const saveDocTemplate = gql`
    mutation DocTemplateSaveMutation($instructionId: Int!, $content: String!) {
      docTemplateSave(instructionId: $instructionId, content: $content) {
        ok
      }
    }
  `;

  export const createUser = gql`
    mutation UserCreateMutation($email: String!) {
      userCreate(email: $email) {
        ok
        error
      }
    }
  `;

  export const createUserPasswordToken = gql`
    mutation UserPasswordTokenCreateMutation($email: String!) {
      userPasswordTokenCreate(email: $email) {
        ok
      }
    }
  `;

  export const resetUserPassword = gql`
    mutation UserPasswordResetMutation($token: String!, $email: String!, $password: String!) {
      userPasswordReset(token: $token, email: $email, password: $password) {
        ok
        status
      }
    }
  `;


  export const createSubscriptionIntent = gql`
    mutation SubscriptionIntentCreateMutation($priceLookupKey: String!) {
      subscriptionIntentCreate(priceLookupKey: $priceLookupKey) {
        ok
        clientSecret
      }
    }
  `;


  export const cancelSubscriptionAtPeriodEnd = gql`
    mutation SubscriptionCancelAtPeriodEndMutation($subscriptionId: String!, $cancel: Boolean!) {
      subscriptionCancelAtPeriodEnd(subscriptionId: $subscriptionId, cancel: $cancel) {
        ok
      }
    }
  `;


  export const copySubscriptions = gql`
    mutation SubscriptionsCopyMutation {
      subscriptionsCopy {
        ok
        subscriptions {
          subscriptionId
          status
          currency
          daysUntilDue
          payPeriod
          price
          priceLookupKey
          product
          cancelAtPeriodEnd
          currentPeriodStart
          currentPeriodEnd
          collectionMethod
          cancelAt
          canceledAt
          startDate
          endedAt
          testMode
        }
      }
    }
  `;


  export const createCrash = gql`
    mutation CrashCreateMutation($error: String!, $stackTrace: String) {
      crashCreate(error: $error, stackTrace: $stackTrace) {
        ok
      }
    }
  `;


  export const updateRepoMemberAccess = gql`
    mutation RepoMemberAccessUpdateMutation($repoId: Int!, $memberUsername: String!, $access: String!) {
      repoMemberAccessUpdate(repoId: $repoId, memberUsername: $memberUsername, access: $access) {

        ... on Success {
          featureLimited
          ok
        }
        
        ... on FeatureLimited {
          featureLimited
          limitType
        }

      }
    }
  `;


  export const addRepoMember = gql`
    mutation RepoMemberAddMutation($repoId: Int!, $email: String!, $access: String!) {
      repoMemberAdd(repoId: $repoId, email: $email, access: $access) {

        ... on Success {
          featureLimited
          ok
        }
        
        ... on FeatureLimited {
          featureLimited
          limitType
        }

      }
    }
  `;


  export const removeRepoMember = gql`
    mutation RepoMemberRemoveMutation($repoId: Int!, $memberUsername: String!) {
      repoMemberRemove(repoId: $repoId, memberUsername: $memberUsername) {
        ok
      }
    }
  `;


  export const acceptRepoMemberInvite = gql`
    mutation RepoMemberAcceptInviteMutation($repoId: Int!) {
      repoMemberAcceptInvite(repoId: $repoId) {
        ok
        docTemplate {
          content
        }
      }
    }
  `;


  export const rejectRepoMemberInvite = gql`
    mutation RepoMemberRejectInviteMutation($repoId: Int!) {
      repoMemberRejectInvite(repoId: $repoId) {
        ok
      }
    }
  `;


  export const updateRepoName = gql`
    mutation RepoNameUpdateMutation($repoId: Int!, $name: String!) {
      repoNameUpdate(repoId: $repoId, name: $name) {
        ok
      }
    }
  `;


} // ns mutation
