import { ContentTools } from "../../../document/states/persistent/content";
import { ContentTypes } from "../../../document/states/persistent/content";
import * as Types from './types';


class CellImagsRepo {
  private _repo: Types.Repo;

  constructor() {
    this._repo = {};
  }

  addImage(
    imageAddr: ContentTypes.ImageAddr,
    imageElement: HTMLDivElement,
  ) {
    const imageKey = ContentTools.getImageKey(imageAddr);
    // console.log("add image");

    if ( imageKey in this._repo ) {
      const msg = `Image already in repo`;
      throw new Error(msg);
    }

    const repoItem: Types.RepoItem = {
      element: imageElement
    }

    this._repo[imageKey] = repoItem;
  }

  removeImage(
    imageAddr: ContentTypes.ImageAddr,
  ) {
    const imageKey = ContentTools.getImageKey(imageAddr);

    if ( imageKey in this._repo === false ) {
      const msg = `Image not in repo`;
      throw new Error(msg);
    }

    delete this._repo[imageKey];
  }

  getImage(imageAddr: ContentTypes.ImageAddr) {
    const imageKey = ContentTools.getImageKey(imageAddr);

    if ( ! (imageKey in this._repo) ) {
      const msg = `Image not in repo`;
      throw new Error(msg);
    }

    const repoItem = this._repo[imageKey];
    return repoItem;
  }
}


export default CellImagsRepo;