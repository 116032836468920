import React from 'react';
import * as Types from '../../types';

import { IconWrapper } from './styles';
import { Item }        from './styles';
import { IconScaler }  from './styles';
import { TextWrapper } from './styles';
import { KeyBindingWrapper } from './styles';
import { settings } from 'app/configs';
import useMenubarItemContext from '../menubar-item-context/use-context';


interface Props extends Types.MenubarItemPanelItemProps {
}


export const MenubarItemPanelItemComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
  } = props;

  const Icon = config.icon;

  const {
    cancelBlur
  } = useMenubarItemContext();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    setTimeout(() => {
      // This timeout is here, just to 
      // give visual feedback to user
      // - by setting css item clicked active
      // selector => chaning it color background.
      // So user know what he clicked - before 
      // closing panel.
      // closeItem();

      config.onClick?.(event);
    }, settings.touch.visualFeedbackDelay);
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    cancelBlur();
  }


  return (
    <Item 
      data-test={config.dataTest}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
    >
      <IconWrapper>
        {
          Icon &&
          <IconScaler>
            <Icon />
          </IconScaler>
        }
      </IconWrapper>

      <TextWrapper>
        { config.title }
      </TextWrapper>

      <KeyBindingWrapper>
        { config.keysLabel }
      </KeyBindingWrapper>

    </Item>
  );
}
