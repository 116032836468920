import React        from 'react';
import { useState } from 'react';
import jtl from 'tools/jtl';

import TextWrap         from 'lego/styles/text-wrap';
import TooltipComponent from 'lego/components/tooltip';
import { DocumentRelease } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { ListItem }    from './styles';
import { ReleaseDate } from './styles';
import { Revision }    from './styles';
import { BtnsWrapper } from './styles';
import { BtnWrapper }  from './styles';
// import { IconDelete }  from './styles';
// import { IconEdit }    from './styles';


interface BtnDef {
  tooltip: string;
  onClick: (event?: any) => void;
  Icon: any;
}


interface Props {
  release: DocumentRelease;
  onReleseSelect: (release: DocumentRelease) => void;
}


export const ReleaseItemComponent: React.FC<Props> = (props: Props) => {
  const {
    release,
    onReleseSelect
  } = props;

  const t = useTranslations();
  const [isHover, setIsHover] = useState(false);

  const handlePointerEnter = () => {
    setIsHover(true);
  }

  const handlePointerLeave = () => {
    setIsHover(false);
  }

  const handleReleaseSelect = () => {
    onReleseSelect(release);
  }

  const getRevText = () => {
    const title    = t("document revision, list item");
    const revision = release.revision;
    const txt      = `${title} ${revision}`

    return txt;
  }

  const getDateText = () => {
    const date = release.dateCreated;
    const [dateTxt, timeTxt]  = jtl.date.toYYYYMMDD(new Date(date));
    return `${dateTxt}, ${timeTxt}`;
  }
  
  // const renderButton = (btnDef: BtnDef) => {
  //   const Icon = btnDef.Icon;
  //   return (
  //     <TooltipComponent title={btnDef.tooltip}>
  //       <BtnWrapper onClick={btnDef.onClick}>
  //         <Icon />
  //       </BtnWrapper>
  //     </TooltipComponent>
  //   );
  // }

  return (
    <ListItem
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      onClick={handleReleaseSelect}
    >
      <Revision>
        <TextWrap>
          { getRevText() }
        </TextWrap>
      </Revision>

      <ReleaseDate>
        <TextWrap>
        { getDateText() }
        </TextWrap>
      </ReleaseDate>
    </ListItem>
  );
}
