import { Size } from "app/arch/types";
import * as jwtDecode from 'jwt-decode';



/************* 
 * 
JTL namespace
 *
 *************/


export namespace jtl {

  
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends object ? 
    RecursivePartial<T[P]> : T[P];
};



// Types
export type Area = {
  x: number,
  y: number,
  width:  number,
  height: number,
}


export namespace string {
  export const capitalizeFirstLetter = (text: string): string => {
    if (text.length === 0) {
      return text;
    }
    return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
  }

  export const toFilename = (text: string): string => {
    const filename = text
      .replace(/^\s+|\s+$/g, '')
      .replace(/\-/g, ' ')
      .replace(/\s+/g, '_')
      .replace(/[^\w]/g, '')
    ;

    return filename;
  }
}

export namespace core {

  export const range = (start: number, stop: number, step = 1) => {
    const length = (stop - start - 1) / step + 1;
    const arr = Array.from({ length }, 
      (_, i) => start + i * step);

    return arr;
  }

  // FIXME
  export const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return function (this: any, ...args: any[]) {
      // console.log(`clear ${timeoutId}`);
      
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        fn.apply(this, args);
        timeoutId = null;
      }, ms);

      // console.log(`set ${timeoutId}`);
      return timeoutId;
    };
  };

  export const callOnce = <T extends (...args: any[]) => any>(fn: T): (...args: Parameters<T>) => ReturnType<T> => {
    let called = false;
    let result: ReturnType<T>;
  
    return (...args: Parameters<T>): ReturnType<T> => {
      if (!called) {
        result = fn(...args);
        called = true;
      }
      return result;
    };
  }
} // namespace core

export namespace date {
  export const now = () => {
      const now: Date = new Date();
      return now;
  }

  export const toYYYYMMDD = (date: Date): [string, string] => {
    const yearRaw:  number = date.getFullYear();
    const monthRaw: number = date.getMonth() + 1; // Month is zero-indexed
    const dayRaw:   number = date.getDate();

    // Padding with leading zeros if necessary
    const year:  string = `${yearRaw}`;
    const month: string = monthRaw < 10 ? `0${monthRaw}` : `${monthRaw}`;
    const day:   string = dayRaw < 10 ?   `0${dayRaw}`   : `${dayRaw}`;

    const dateTxt: string = `${year}-${month}-${day}`;

    // Time part
    const hours: string = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    const minutes: string = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    const timeTxt: string = `${hours}:${minutes}`; // Format hhmm

    return [dateTxt, timeTxt];
  }
}

export namespace html {

  export const toString = (html: string) => {
    const parser    = new DOMParser();
    const htmlConv  = html.replace(/<br\s*\/?>/gi, ' ');
    const doc       = parser.parseFromString(htmlConv, 'text/html');
    const plainText = doc.body.textContent || '';

    return plainText;
  }

} // namespace html


export namespace object {
  export const isEmpty = (obj: any | null | undefined) => {
    if (obj === null) {
      console.warn(`Obj is null`);
      return true;
    }

    if (obj === undefined) {
      console.warn(`Obj is undefined`);
      return true;
    }

    return Object.keys(obj).length === 0;
  }

  export const update = <T>(
    toBeUpdated: T, 
    update_: RecursivePartial<T>
  ) => {
    for (const key in update_) {
      if (toBeUpdated[key] === undefined) {
        const msg = `Missing key: ${key}, in obj ${toBeUpdated}`;
        throw new Error(msg);
      }

      if (typeof update_[key] === 'object') {
        update(
          toBeUpdated[key], 
          update_[key] as object);
      }
      else {
        toBeUpdated[key] = update_[key] as any;
      }
    }
  }

  export const copy = (
    obj: any
  ) => {
    return JSON.parse(JSON.stringify(obj));
  }
  
  export const hash = (
    data: string | object | null,
    maxLen: number = 8
  ): string => {
    if (data === null) {
      return 'null';
    }

    const dataIn = (
      typeof(data) === 'string' ?
      data :
      JSON.stringify(data)
    );

    let key: number = 0;
    for (let i = 0; i < dataIn.length; i++) {
        key = ((key << 5) + key) ^ dataIn.charCodeAt(i);
    }
    const hash = key >>> 0; 
    const hexHash = hash.toString(16);
    const hashFormatted =  hexHash.padStart(maxLen, '0').slice(0, maxLen);

    return hashFormatted;
  }

} // namespace object

export namespace geometry {

  export const calculateAngle = (
    x1: number, y1: number,
    x2: number, y2: number
  ) => {
    const xd = x2 - x1;
    const yd = y2 - y1;
    const angle = Math.atan2(yd, xd) - Math.PI / 2;

    return normalizeAngle(angle);
  }

  export const calculateDistance = (
    x1: number, y1: number,
    x2: number, y2: number
  ) => {

    const xd = x2 - x1;
    const yd = y2 - y1;

    const distance = Math.sqrt(xd ** 2 + yd ** 2);
    return distance;
  }

  export const intersecting = (area1: Area, area2: Area): boolean => {
    if (area1.x > (area2.x + area2.width) || area2.x > (area1.x + area1.width)) {
      return false;
    }
  
    if (area1.y > (area2.y + area2.height) || area2.y > (area1.y + area1.height)) {
      return false;
    }
  
    return true;
  }

  // This vector rotation, is really point rotation
  // around (0, 0). I'm not sure if I should call it
  // vector rotation, but this is how it is needed 
  // now - therefore leave it as it is for now.
  export const rotateVector = (radians: number, size: Size) => {
    const [x, y] = size;
    const  vecRotated = rotateBase(radians, x, y) as Size;

    return vecRotated;
  }

  export const rotatePoint = (
    radians: number, 
    px: number, py: number, 
    cx: number, cy: number
  ) => {
    // Translate to (0, 0)
    const xNormalized = px - cx;
    const yNormalized = py - cy;

    // Rotate
    const [xnew, ynew ] = rotateBase(radians, xNormalized, yNormalized);
    
    // Translate point back
    px = xnew + cx;
    py = ynew + cy;

    return [px, py];
  }

  // This is basic point rotation around point(0, 0)
  export const rotateBase = (
    radians: number,
    x: number,
    y: number
  ) => {
    const sin = Math.sin(radians);
    const cos = Math.cos(radians);

    const xPrim = x * cos - y * sin;
    const yPrim = x * sin + y * cos;

    return [xPrim, yPrim];
  }

  export const normalizeAngle = (radians: number) => {
    const FULL_ANGLE = 2 * Math.PI;

    while (radians < 0) {
      radians = radians + FULL_ANGLE;
    }

    return (radians % FULL_ANGLE);
  }
}

export namespace number {
  export const moneyFormat = (num: number) => {
    const options: Intl.NumberFormatOptions = { 
      style: 'currency', 
      currency: 'USD',
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0,
    };
    const numFormatted = num.toLocaleString('en-US', options);
    return numFormatted;
  }
}

export namespace grid {

  // There is a need for 2 seperate applyGrid methods
  // for position and size. The reason for that is,
  // imagine you grab top-left corner of a widget - and
  // enlarge it by moving cursor to its left. In this
  // scenario, widget position will decrease (higher to 
  // lower values), while widget size will increase 
  // (lower to higher values).  Therefore if condition 
  // for 'middle' value grid - becomes problematic - 
  // how it should be handled. It must be handle for 
  // position and size differently, if not - 
  // size and position grid snap will be 
  // desynchornized by 1 pixel.

  export const applyToSize = (
    width: number, height: number,
    gridWidth: number, gridHeight: number
  ) => {
    let remWidth = width % gridWidth;
    let remHeight = height % gridHeight;
  
    width = width - remWidth;
    height = height - remHeight;
  
    //
    // '>' make a difference 
    //
    if (remWidth > gridWidth / 2) {
      width += gridWidth;
    }
    if (remHeight > gridHeight / 2) {
      height += gridHeight;
    }
  
    return [width, height]  as [number, number];
  }

  export const applyToPosition = (
    x: number, y: number, 
    gridWidth: number, gridHeight: number
  ) => {
    let remx = x % gridWidth;
    let remy = y % gridHeight;

    x = x - remx;
    y = y - remy;

    //
    // '>=' make a difference 
    //
    if (remx >= gridWidth / 2) {
      x += gridWidth;
    }
    if (remy >= gridHeight / 2) {
      y += gridHeight;
    }

    return [x, y] as [number, number];
  }
} // namespace grid


export namespace serialize {
  
  export const serialize = (obj: any): string => {
      let txt = JSON.stringify(obj);
      return txt;
      // return hexEncode(txt);
  }

  export const deserialize = (txt: string): any => {
    return JSON.parse(txt);
    // let txtDecoded = hexDecode(txt);
    // return JSON.parse(txtDecoded);
  }

  const hexEncode = (txt: string): string => {
      let hex, i;

      let result = "";
      for (i=0; i<txt.length; i++) {
          hex = txt.charCodeAt(i).toString(16);
          result += ("000"+hex).slice(-4);
      }

      return result
  }

  const hexDecode = (txt: string): string => {
    var j;
    var hexes = txt.match(/.{1,4}/g) || [];
    var back = "";
    for(j = 0; j<hexes.length; j++) {
        back += String.fromCharCode(parseInt(hexes[j], 16));
    }

    return back;
  }
} // namespace serialize
  

export namespace ui {
  let __getScrollbarWidthValue: null | number = null;

  export const getScrollbarWidth = () => {
    if (__getScrollbarWidthValue === null) {
      // Create a temporary div with an overflow property set to scroll
      const div = document.createElement('div');
      div.style.overflow = 'scroll';
      div.style.position = 'absolute';
      div.style.top = '-9999px';
      document.body.appendChild(div);
    
      // Measure the width of the content and subtract it from the width of the container
      const scrollbarWidth = div.offsetWidth - div.clientWidth;
    
      // Remove the temporary div
      document.body.removeChild(div);
      __getScrollbarWidthValue = scrollbarWidth;
    }
  
    return __getScrollbarWidthValue;
  }
} // namespace ui

export namespace css {

  export const valueToNumber = (cssValue: string | undefined | null | number, fallbackValue?: number) => {
    if (cssValue === undefined || cssValue === null) {
      return fallbackValue ?? 0;
    }

    if (typeof cssValue === 'number') {
      return cssValue;
    }  
    
    const valueStr = cssValue.replace(/(px|%)/g, "");
    const value = parseFloat(valueStr);
    return value;
  }

  export const valueToString = (cssValue: string | undefined, fallbackValue?: string) => {
    if (cssValue === undefined) {
      return fallbackValue ?? '';
    }
  
    return cssValue;
  }

  export const getValue = (css: any, cssAttr: string) => {
    return css && css.hasOwnProperty(cssAttr) ? css[cssAttr] : null;
  }

  export const hasProps = (CSSToCheck: any, CSSProps: any): boolean => {
    const cssAttrNames = Object.keys(CSSProps);

    const match = cssAttrNames.every((cssAttr) => {
      const cssValueCurrent  = jtl.css.getValue(CSSToCheck, cssAttr);
      const cssValueExpected = (CSSProps)[cssAttr];
      return cssValueCurrent === cssValueExpected;
    });

    return match;
  }

  export const getFramingWidth = (css: any) => {
    const borderWidth  = jtl.css.valueToNumber(css.borderWidth);
    const outlineWidth = jtl.css.valueToNumber(css.outlineWidth);
    const frameWidth = borderWidth + outlineWidth;

    return frameWidth;
  }
}

export namespace buttons {
  export const left = 0;
  export const middle = 1;
  export const right = 2;
} // namespace buttons

export namespace color {

  export const  isColorString = (color: string): boolean => {
    const hexPattern = /^#([0-9A-Fa-f]{3}){1,2}$/;
    const rgbPattern = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
  
    // Test against both hex and RGB patterns
    if (hexPattern.test(color)) {
      return true;
    }
    
    const rgbMatch = color.match(rgbPattern);
    if (rgbMatch) {
      // Check if RGB values are in the range 0-255
      const [_, r, g, b] = rgbMatch;
      return [r, g, b].every(value => Number(value) >= 0 && Number(value) <= 255);
    }
    
    return false;
  }

  export const hex2rgba = (color: string, opacity: number = 1): string => {
    if ( ! color.startsWith('#')) {
      throw new Error('Color argument must start with "#"');
    }

    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    opacity = Math.min(1, Math.max(0, opacity));

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  export const  rgba2hex = (rgba: string) => {
    const rgbaRegex = /^rgba\((\d+),(\d+),(\d+),(\d+(\.\d+)?)\)$/;
    const rgbaNoSpaces = rgba.replace(/\s/g, '').toLowerCase();
    const match = rgbaNoSpaces.match(rgbaRegex);
    
    if ( ! match) {
      throw new Error(`Can't parse rgba color: '${rgba}'`);
    }

    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);
    const opacity = parseFloat(match[4]);

    // Convert the color values to hexadecimal notation
    const rHex = r.toString(16).padStart(2, "0");
    const gHex = g.toString(16).padStart(2, "0");
    const bHex = b.toString(16).padStart(2, "0");

    const hexColor = `#${rHex}${gHex}${bHex}`;

    return hexColor;
  }

  export const expandHex = (color: string): string => {
    let hex = color.replace('#', '');

    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }
  
    return `#${hex}`;
  }

  export const hex2argb = (color: string): string => {
    if ( ! color.startsWith('#')) {
      throw new Error('Color argument must start with "#"');
    }
    
    const colorExpand = expandHex(color);
    const argb = colorExpand.replace('#', 'FF');

    return `${argb}`;
  };

  export const getOpacity = (color: string): number => {
    if (color.startsWith('#')) {
      return 1;
    }

    if ( color.length === 0) {
      return 1;
    }

    const match = color.match(/rgba\(\s*(\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\s*\)/);
    if (match && match[4]) {
      return parseFloat(match[4]);
    } else {
      throw new Error(`Invalid RGBA notation, -${color}-`);
    }
  };
} // namespace color


export namespace email {
  
  export const isValid = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

} // namespace email

export namespace password {
  
  export const isValid = (password: string) => {
    return password.length >= 5;
  }

} // namespace password

export namespace selection {
  export const moveCursorToEnd = (element: any | null) => {
    if (element === null) {
      console.error("Can't move cursor, element is null");
      return;
    }

    let selection = window.getSelection();
    if (selection === null) {
      console.error("Can't create selection");
      return;
    }

    let range = window.document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);

    element.focus();
  }

  export const readCursorPosition = (element: any | null): (number | null) => {
    if (element === null) {
      console.error("Can't read cursor position, element is null");
      return null;
    }

    const selection = window.getSelection()!;
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const clonedRange = range.cloneRange();
      clonedRange.selectNodeContents(element);
      clonedRange.setEnd(range.endContainer, range.endOffset);
      const position = clonedRange.toString().length;
      // console.log(clonedRange.startOffset);
      // console.log(clonedRange.endOffset);

      return position;
    }

    return 0;
  }

  export const moveCursorPosition = (element: any | null) => {
    // This is not finished
    // 
    
    if (element === null) {
      console.error("Can't read cursor position, element is null");
      return null;
    }

    let selection = window.getSelection();
    if (selection === null) {
      console.error("Can't create selection");
      return;
    }

    let range = window.document.createRange();
    range.selectNodeContents(element);
    range.setStart(element.childNodes[0], 0);
    range.setEnd(element.childNodes[2], 0);
    // range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);

    element.focus();
  }
}

export namespace epoch {
  export const toUTCDate = (epochTime: number): string => {
    const date = new Date(epochTime * 1000);
    const year  = date.getUTCFullYear();
    const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const day   = ('0' + date.getUTCDate()).slice(-2);

    return `${year}.${month}.${day}`;
  }

  export const toUTCTime = (epochTime: number): string => {
    const date = new Date(epochTime * 1000);
    const hours   = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);

    return `${hours}:${minutes}`;
  }

  export const toGMT2 = (epochTime: number): string => {
    const date = new Date(epochTime * 1000);

    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Europe/Berlin',
        hour12: false, 
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const formattedParts = formatter.formatToParts(date);

    let year = '';
    let month = '';
    let day = '';

    formattedParts.forEach(part => {
        if (part.type === 'year') year = part.value;
        if (part.type === 'month') month = part.value;
        if (part.type === 'day') day = part.value;
    });

    return `${year}.${month}.${day}`;
  }
}


export namespace jwt {

  export const isExpired = (token: string): boolean => {
    try {
      const decodedToken = jwtDecode.jwtDecode(token);
      if ( ! decodedToken.exp ) {
        return true;
      }

      const expTime = decodedToken.exp * 1000; // Convert to milliseconds
      // console.log((expTime - Date.now()) / 1000);
      return expTime < Date.now();

    } catch (error) {
      console.error('Error checking token expiration:', error);
      return true;
    }
  };

}

} // namespace jtl
