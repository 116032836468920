import environment from "app/environment";
import { usePDFDownload } from "app/ui-v2/editor-instruction/hooks/use-pdf-download";


//---------------------------
// 
// Hook Use Logo URL
// 

interface FnProps {
  documentId: number,
  revision: number,
  showProgress: boolean,
}

const useDocumentReleaseDownload = () => {
  const downloadPDF = usePDFDownload();
  
  const getReleaseUrl = (documentId: number, revision: number) => {
    const url = environment.backend.getInstructionReleaseUrl(
      documentId, 
      revision,
    );

    return url;
  }

  const downloadRelease = (props: FnProps) => {
    const {
      documentId,
      revision,
      showProgress,
    } = props;

    const url = getReleaseUrl(documentId, revision);
    downloadPDF({url, showProgress})
  }

  return downloadRelease;
}

export default useDocumentReleaseDownload;