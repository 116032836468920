import ExcelJS from 'exceljs';
import domtoimage from 'dom-to-image';

import jtl from 'tools/jtl';
import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import { ContentTools } from "app/arch/editor-instruction/document/states/persistent/content";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import TextConverter from './text-converter';
import TextConverterCss from './text-converter-css';
import AssetsRepo from './assets-repo';
import XLSRows from './xls-rows/xls-rows';


class ExcelExporter {
  private _workbook: ExcelJS.Workbook;
  private _worksheet: ExcelJS.Worksheet;
  private _docState: DocState;

  private _assetsRepo: AssetsRepo;
  private _xlsRows: XLSRows;

  constructor(docState: DocState) {
    this._workbook = new ExcelJS.Workbook();
    this._worksheet = this._workbook.addWorksheet('My Sheet');
    this._docState = docState;

    this._assetsRepo = new AssetsRepo();
    this._xlsRows = new XLSRows();
  }

  get content() { return this._docState.content; }
  get assetsRepo() { return this._assetsRepo; }

  get offset() {
    return {
      content: 1
    }
  }

  private delay(ms: number): Promise<void> {
    console.log(`delay ${ms}m`);
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async donwload() {
    this._addColumns();

    // await this._addDocHeader();
    
    await this._addSections();
    this._addTableHeader();
    this._setLayout();
    await this._download();
  }

  private _setLayout() {
    this._setRowsHeight();
    this._setColumnsWidth();
  }

  private _setRowsHeight() {
    type GetHeightFn = (cellAddr: ContentTypes.CellAddr) => null | number;
    const CellHeightFnMap: {[columnType in ContentTypes.ColumnType]: GetHeightFn} = {
      [ContentTypes.ColumnType.INDEX]: (cellAddr: ContentTypes.CellAddr) => {
        return null;
      },
      [ContentTypes.ColumnType.TEXT]: (cellAddr: ContentTypes.CellAddr) => {
        return null;
      },
      [ContentTypes.ColumnType.IMAGES]: (cellAddr: ContentTypes.CellAddr) => {
        const cell = this.content.getCell(cellAddr) as ContentTypes.ImagesCell;

        const imagesAddrs = cell.images.addrs;
        let height = 0;

        imagesAddrs.forEach(imageAddr => {
          const imgItem = this.assetsRepo.cellImages.getImage(imageAddr);
          const bbox = imgItem.element.getBoundingClientRect();
          
          height = Math.max(height, bbox.height);
        });

        // Base on emperical observation
        // when i set height here 100,
        // then it is 133px in generated spreadsheet
        const MAGIC_SCALE = 100 / 133;

        return height * MAGIC_SCALE;
      },
      [ContentTypes.ColumnType.MARKERS]: (cellAddr: ContentTypes.CellAddr) => {
        const cell = this.content.getCell(cellAddr) as ContentTypes.MarkersCell;

        const markersAddrs = cell.markers.addrs;
        let height = 0;

        markersAddrs.forEach(markerAddr => {
          const imgItem = this.assetsRepo.cellMarkers.getMarker(markerAddr);
          const bbox = imgItem.element.getBoundingClientRect();
          
          height = Math.max(height, bbox.height);
        });

        // Base on emperical observation
        // when i set height here 100,
        // then it is 133px in generated spreadsheet
        const MAGIC_SCALE = 100 / 133;

        return height * MAGIC_SCALE;
      },
    }

    //-------------------

    const columnsAddrs = this.content.getColumnsAddrs();
    const sectionsAddrs = this.content.getSectionsAddrs();

    sectionsAddrs.forEach((sectionAddr) => {
      const rowsAddrs = this.content.getRowsAddrs(sectionAddr);
      rowsAddrs.forEach((rowAddr) => {
        const xlsRow = this._xlsRows.getRow(rowAddr);

        let rowHeight = xlsRow.height;

        columnsAddrs.forEach(columnAddr => {
          const columnProps = this.content.getColumnProps(columnAddr);

          const cellAddr: ContentTypes.CellAddr = {
            ...rowAddr,
            ...columnAddr,
          }

          const getCellHeight = CellHeightFnMap[columnProps.type];
          const cellHeight = getCellHeight(cellAddr);

          if ( cellHeight === null ) {
            return;
          }

          if ( rowHeight === undefined ) {
            rowHeight = cellHeight;
          }
          else {
            rowHeight = Math.max(cellHeight, rowHeight);
          }
        });

        xlsRow.height = rowHeight;
        xlsRow.commit();
      });
    });
  }

  private _setColumnsWidth() {

    type GetWidthFn = (cellAddr: ContentTypes.CellAddr) => null | number;

    const CellWidthFnMap: {[columnType in ContentTypes.ColumnType]: GetWidthFn} = {
      [ContentTypes.ColumnType.INDEX]: (cellAddr: ContentTypes.CellAddr) => {
        return null;
      },
      [ContentTypes.ColumnType.TEXT]: (cellAddr: ContentTypes.CellAddr) => {
        return null;
      },
      [ContentTypes.ColumnType.IMAGES]: (cellAddr: ContentTypes.CellAddr) => {
        const cell = this.content.getCell(cellAddr) as ContentTypes.ImagesCell;

        const imagesAddrs = cell.images.addrs;
        let width = 0;

        imagesAddrs.forEach(imageAddr => {
          const imgItem = this.assetsRepo.cellImages.getImage(imageAddr);
          const bbox = imgItem.element.getBoundingClientRect();
          
          width = Math.max(width, bbox.width);
        });

        // Base on emperical observation
        // when i set width here 100,
        // then it is 600px in generated spreadsheet
        //
        // GPT:
        // The character-based width in exceljs roughly corresponds to:
        // 1 character unit ≈ 7 pixels (for the default font, typically Calibri 11). 

        const MAGIC_SCALE = 1 / 6;
        const widthScaled = width * MAGIC_SCALE;

        return widthScaled;
      },
      [ContentTypes.ColumnType.MARKERS]: (cellAddr: ContentTypes.CellAddr) => {
        const cell = this.content.getCell(cellAddr) as ContentTypes.MarkersCell;

        const markersAddrs = cell.markers.addrs;
        let width = 0;

        markersAddrs.forEach(markerAddr => {
          const markerItem = this.assetsRepo.cellMarkers.getMarker(markerAddr);
          const bbox = markerItem.element.getBoundingClientRect();
          width = Math.max(width, bbox.width);
        });

        // Base on emperical observation
        // when i set width here 100,
        // then it is 600px in generated spreadsheet

        const MAGIC_SCALE = 1 / 6;
        const widthScaled = width * MAGIC_SCALE;
        
        return widthScaled;
      },
    }

    //-------------------

    const columnsAddrs = this.content.getColumnsAddrs();
    const sectionsAddrs = this.content.getSectionsAddrs();

    columnsAddrs.forEach(columnAddr => {
      let columnWidth = 0;

      sectionsAddrs.forEach((sectionAddr) => {
        const rowsAddrs = this.content.getRowsAddrs(sectionAddr);
        rowsAddrs.forEach((rowAddr) => {
          const cellAddr: ContentTypes.CellAddr = {
            ...rowAddr,
            ...columnAddr,
          }

          const columnProps = this.content.getColumnProps(columnAddr);
          const getCellWidth = CellWidthFnMap[columnProps.type];
          const cellWidth = getCellWidth(cellAddr);

          if ( cellWidth !== null ) {
            columnWidth = Math.max(cellWidth, columnWidth);
          }
        });
      });

      const columnKey = ContentTools.getColumnKey(columnAddr);
      const xlsColumn = this._worksheet.getColumn(columnKey);

      if ( columnWidth !== 0 ) {
        xlsColumn.width = columnWidth;
      }
    });
  }

  private async _download() {
    const meta = this._docState.metaData;
    const title = meta.getTitle();
    const filename = jtl.string.toFilename(title)
    const fullpath = `${filename}.xlsx`;

    const excelBuffer = await this._workbook.xlsx.writeBuffer();
    const blobExcel = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a link element to download the Excel file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blobExcel);
    link.setAttribute('download', fullpath);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private _addDocHeader() {
    const columnsAddrs = this.content.getColumnsAddrs();

    // Create row with empty cells
    //
    const xlsRowCells = columnsAddrs.reduce((acc, columnAddr) => {
      const columnKey = ContentTools.getColumnKey(columnAddr);
      const xlsCell = { [columnKey]: '' };
      const ret = { ...acc, ...xlsCell };
      return ret;
    }, {});

    const xlsRow = this._worksheet.addRow(xlsRowCells);
    this._worksheet.addRow(xlsRowCells);
    this._worksheet.addRow(xlsRowCells);
    this._worksheet.addRow(xlsRowCells);

    const cellRowIdx = 2;
    const cellIdxStart = 1;
    const cellIdxStop = cellIdxStart + columnsAddrs.length - 1;
    
    this._worksheet.mergeCells(
      cellRowIdx, cellIdxStart, 
      cellRowIdx, cellIdxStop
    );
  }

  private _addColumns() {
    const columnsAddrs = this.content.getColumnsAddrs();

    const xlsColumns = columnsAddrs.map(columnAddr => {
      const columnName = this.content.getColumnName(columnAddr);
      const columnWidth = this.content.getColumnWidth(columnAddr);
      const columnKey = ContentTools.getColumnKey(columnAddr);

      const xlsColumn = { 
        key: columnKey, 
        header: columnName, 
        width: columnWidth / 6 
      };

      return xlsColumn;
    });

    this._worksheet.columns = xlsColumns;
  }

  private _addTableHeader() {
    const columnsAddrs = this.content.getColumnsAddrs();
    columnsAddrs.forEach((columnAddr, idx) => {
      const textConverter = new TextConverterCss();
  
      const columnProps = this.content.getColumnProps(columnAddr);
      const viewsCommon = this._docState.viewsCommon;

      const css = viewsCommon.getTableHeaderCSS();
      const xlsRow = this._worksheet.getRow(1);
      const xlsCell = xlsRow.getCell(idx+1);

      textConverter.convert(
        xlsCell,
        css,
        columnProps.name
      );
    });
  }

  private async _addSections() {
    const sectionsAddrs = this.content.getSectionsAddrs();
    await Promise.all(sectionsAddrs.map(sectionAddr => this._addSection(sectionAddr)));
  }

  private async _addSection(sectionAddr: ContentTypes.SectionAddr) {
    const rows = this.content.getSectionRows(sectionAddr);
    await Promise.all(rows.addrs.map(rowAddr => this._addRow(rowAddr)));
  }

  private async _addRow(rowAddr: ContentTypes.RowAddr) {
    const columnsAddrs = this.content.getColumnsAddrs();

    // Create row with empty cells
    //
    const xlsRowCells = columnsAddrs.reduce((acc, columnAddr) => {
      const columnKey = ContentTools.getColumnKey(columnAddr);
      const xlsCell = { [columnKey]: '' };
      const ret = { ...acc, ...xlsCell };
      return ret;
    }, {});

    const xlsRow = this._worksheet.addRow(xlsRowCells);
    this._xlsRows.addRow(rowAddr, xlsRow);

    // Copy cell content
    //

    await Promise.all(columnsAddrs.map((columnAddr, idx) => {
      const cellAddr: ContentTypes.CellAddr = {
        ...rowAddr,
        columnId: columnAddr.columnId
      };
  
      const xlsCell = xlsRow.getCell(idx + 1);
      return this._copyCell(cellAddr, xlsCell);
    }));
  }

  private async _copyCell(
    cellAddr: ContentTypes.CellAddr,
    xlsCell: ExcelJS.Cell,
  ) {
    const columnType = this.content.getColumnType(cellAddr);

    type GetCellContent =  (
      cellAddr: ContentTypes.CellAddr,
      xlsCell: ExcelJS.Cell,
    ) => Promise<void>; 

    const copyFnsMap: {[key in ContentTypes.ColumnType]: GetCellContent } = {
      [ContentTypes.ColumnType.INDEX  ]: async (cellAddr: ContentTypes.CellAddr, xlsCell: ExcelJS.Cell) => await this._copyCellIndex(cellAddr, xlsCell),
      [ContentTypes.ColumnType.TEXT   ]: async (cellAddr: ContentTypes.CellAddr, xlsCell: ExcelJS.Cell) => await this._copyCellText(cellAddr, xlsCell),
      [ContentTypes.ColumnType.IMAGES ]: async (cellAddr: ContentTypes.CellAddr, xlsCell: ExcelJS.Cell) => await this._copyCellImages(cellAddr, xlsCell),
      [ContentTypes.ColumnType.MARKERS]: async (cellAddr: ContentTypes.CellAddr, xlsCell: ExcelJS.Cell) => await this._copyCellMarkers(cellAddr, xlsCell),
    }

    const copyCellFn = copyFnsMap[columnType];
    return copyCellFn(cellAddr, xlsCell);
  }

  private _copyCellIndex(
    cellAddr: ContentTypes.CellAddr,
    xlsCell: ExcelJS.Cell,
  ) {
    const idx = this.content.getRowIdxGlobal(cellAddr) + 1;
    xlsCell.value = `${idx}`;
    xlsCell.alignment = { 
      wrapText: true, 
      horizontal: 'center', 
      vertical: 'top' 
    };

    xlsCell.value = {
      'richText': [{
        'font': {
          'bold': true,
          'size': 14,
          'color': {'theme': 1},
          'name': 'Calibri',
          'family': 2,
          'scheme': 'minor'
        },
        'text': `${idx}`
      }]
    };
  }

  private _copyCellText(
    cellAddr: ContentTypes.CellAddr,
    xlsCell: ExcelJS.Cell,
  ) {
    const cell = this.content.getCell(cellAddr) as ContentTypes.TextCell;
    if ( ! cell.editorState ) {
      return;
    }

    const textConverter = new TextConverter();

    const editorState = JSON.parse(cell.editorState);
    const excelJsFormat = textConverter.convert(editorState);

    xlsCell.alignment = { wrapText: true, vertical: 'top' };
    xlsCell.value     = { 'richText' : excelJsFormat };
  }

  private async _copyCellImages(
    cellAddr: ContentTypes.CellAddr,
    xlsCell: ExcelJS.Cell,
  ) {
    // await this.delay(1100);

    const cell = this.content.getCell(cellAddr) as ContentTypes.ImagesCell;
    const imagesAddrs = cell.images.addrs;

    for (let i = 0; i < imagesAddrs.length; i++) {
      const imageAddr = imagesAddrs[i];

      const repoItem = this._assetsRepo.cellImages.getImage(imageAddr);
      const imageEl = repoItem.element;

      if ( ! imageEl ) {
        continue;
      }
      
      const contentOffset = this.offset.content;
      const base64 = await domtoimage.toPng(imageEl, {cacheBust: true});

      const imageId = this._workbook.addImage({
        base64,
        extension: 'png',
      });
        
      const colIdx = this.content.getColumnIdx(cellAddr);
      const rowIdx = this.content.getRowIdxGlobal(cellAddr);
      
      // const imageProps = this.content.cellImages_getImageProps(imageAddr);
      // const imageSize = ContentTools.getImageSize(imageProps.viewArea);
      // const imageScale = imageProps.viewScale; 

      const col = colIdx;
      const row = rowIdx + contentOffset;

      // const width  = imageSize[0] * imageScale;
      // const height = imageSize[1] * imageScale;
      const bbox = imageEl.getBoundingClientRect();
      const width  = bbox.width;
      const height = bbox.height;

      this._worksheet.addImage(imageId, {
        tl: { col, row },
        ext: { width, height },
        editAs: 'absolute'
      });
    }
  }

  private async _copyCellMarkers(
    cellAddr: ContentTypes.CellAddr,
    xlsCell: ExcelJS.Cell,
  ) {
    const cell = this.content.getCell(cellAddr) as ContentTypes.MarkersCell;
    const markersAddrs = cell.markers.addrs;

    for (let i = 0; i < markersAddrs.length; i++) {
      const markerAddr = markersAddrs[i];
      const markerProps = this.content.cellMarkers_getMarkerProps(markerAddr);

      const repoItem = this._assetsRepo.cellMarkers.getMarker(markerAddr);
      const markerEl = repoItem.element;

      if ( ! markerEl ) {
        continue;
      }

      const contentOffset = this.offset.content;
      const base64 = await domtoimage.toPng(markerEl, {cacheBust: true});
      const imageId = this._workbook.addImage({
        base64,
        extension: 'png',
      });
        
      const colIdx = this.content.getColumnIdx(cellAddr);
      const rowIdx = this.content.getRowIdxGlobal(cellAddr);
      
      const repoMarkers = this._docState.repoMarkers;
      const repoMarkerId = {
        markerId: markerProps.repoMarkerAddr.markerId
      }

      const repoMarkerProps = repoMarkers.getMarkerProps(repoMarkerId);

      const _getIcon = (markerProps: RepoMarkersTypes.MarkerProps) => {
        const bbox = markerEl.getBoundingClientRect();
        return [bbox.width, bbox.height];
      }

      const _getLabel = (markerProps: RepoMarkersTypes.MarkerProps) => {
        const bbox = markerEl.getBoundingClientRect();
        return [bbox.width, bbox.height];
      }

      const _getSign = (markerProps: RepoMarkersTypes.MarkerProps) => {
        const bbox = markerEl.getBoundingClientRect();
        return [bbox.width, bbox.height];
      }

      const getMarkerSizeMap: {[markerType in RepoMarkersTypes.MarkerType]: (markerProps: RepoMarkersTypes.MarkerProps) => any} = {
        [RepoMarkersTypes.MarkerType.ICON            ]: _getIcon,
        [RepoMarkersTypes.MarkerType.LABEL           ]: _getLabel,
        [RepoMarkersTypes.MarkerType.SIGN_INFO       ]: _getSign,
        [RepoMarkersTypes.MarkerType.SIGN_MANDATORY  ]: _getSign,
        [RepoMarkersTypes.MarkerType.SIGN_PROHIBITION]: _getSign,
        [RepoMarkersTypes.MarkerType.SIGN_WARNING    ]: _getSign,
      }


      const getMarkerSize = getMarkerSizeMap[repoMarkerProps.type];
      const [width, height] = getMarkerSize(repoMarkerProps);
      // console.log(`marker w: ${width}, h: ${height}`);

      const col = colIdx;
      const row = rowIdx + contentOffset;

      
      this._worksheet.addImage(imageId, {
        tl: { col, row },
        ext: { width, height },
        editAs: 'absolute'
      });
    }
  }
}


export default ExcelExporter;