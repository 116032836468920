import { useProgressModal } from 'lego-v2/progress-modal';

import Errors from "app/arch/tools/errors";
import ExcelExporter from 'app/arch/editor-instruction/excel-exporter/excel-exporter';

import { useDocState } from "app/ui/contexts/document";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { DialogCustomTypes } from 'app/ui/components/editor-instruction/dialogs/dialog-custom';
import { useDialogContinueCancel } from 'app/ui/components/editor-instruction/dialogs/dialog-continue-cancel/use-dialog-continue-cancel';

import { HookConfigProps } from "../types";


let _hookConfig: HookConfigProps | null = null;


export const configureHook = (props: HookConfigProps) => {
  _hookConfig = {
    ...props
  };
}


const useExcelExport = () => {
  const docState = useDocState();
  const t = useTranslations();

  const {
    showProgressModal,
    hideProgressModal,
  } = useProgressModal();

  const dialogContinueCancel = useDialogContinueCancel();

  const showConfirmationDialog = () => {
    dialogContinueCancel.show({
      dataTest: 'editor__dialog__export-xls',
      title:   t('export xls, dialog, title'),
      message: t('export xls, dialog, message'),
      icon: DialogCustomTypes.IconType.QUESTION,
      onContinueHandler: () => {
        showProgressModal();
        exportToExcel();
      }
    });
  }



  const exportToExcel = () => {
    if ( ! _hookConfig ) {
      const msg = `Export excel has been not configured`;
      Errors.hard(msg);
      return;
    }

    const xlsExporter = new ExcelExporter(docState);
    const assetsRepo = xlsExporter.assetsRepo;
    
    const {
      showImagesRender,
      hideImagesRender,
      setAssetsRepo,
    } = _hookConfig

    showProgressModal();
    setAssetsRepo(assetsRepo);
    
    showImagesRender(() => {
      // Once asset repo has been populated
      const exportPromise = xlsExporter.donwload();

      exportPromise.then(() => {
        console.debug('XLS file downloaded successfully!');
      })
      .catch((error) => {
        console.error('Error downloading XLS file:', error);
      })
      .finally(() => {
        hideImagesRender();
        hideProgressModal();
      });
    });
  }

  const hook = (skipDialog?: boolean) => {
    if (_hookConfig === null) {
      const msg = `ExcelExport hook has not been configured`;
      Errors.hard(msg);
      return;
    }

    if (skipDialog) {
      exportToExcel();
      return;
    }

    showConfirmationDialog();
  }

  return hook;
};


export default useExcelExport;