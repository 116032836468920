import { MenuTypes } from 'lego-v2/menu';
import { MenuItemType } from 'lego-v2/menu';

import environment from 'app/environment';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useUndo from 'app/ui-v2/editor-instruction/hooks/use-undo';
import useDocumentSave from 'app/ui-v2/editor-instruction/hooks/use-document-save';
import useEditorCrashTest from 'app/ui-v2/editor-instruction/hooks/use-editor-crash-test';
import useExcelExport     from 'app/ui-v2/editor-instruction/__modules/excel-exporter/hooks/use-excel-export';


const useConfig = () => {
  const t = useTranslations();
  const { undo, redo } = useUndo();

  const saveDocument = useDocumentSave();
  const testCrash = useEditorCrashTest();
  const exportXLS = useExcelExport();

  const getConfigSections = () => {
    const configSections: MenuTypes.MenuSection[] = [
      {
        skip: ! environment.dev,
        items: [
          {
            type: MenuItemType.BUTTON,
            data: {
              title: "Export PDF",
              iconComponent: Icon.File.XLS,
              onClick: (event: React.MouseEvent) => {
                exportXLS(true);
              },
            }
          },
          {
            type: MenuItemType.BUTTON,
            data: {
              title: "Soft crash test",
              iconComponent: Icon.Bug,
              onClick: (event: React.MouseEvent) => {
                testCrash.crashSoft();
              },
            }
          },
          {
            type: MenuItemType.BUTTON,
            data: {
              title: "Hard crash test",
              iconComponent: Icon.Bugs,
              onClick: (event: React.MouseEvent) => {
                testCrash.crasHard();
              },
            }
          },
        ],
      },
      {
        items: [
          {
            type: MenuItemType.BUTTON,
            data: {
              title: t('save'),
              iconComponent: Icon.Save,
              onClick: (event: React.MouseEvent) => {
                saveDocument();
              },
            }
          },
        ],
      },

      {
        items: [
          {
            type: MenuItemType.BUTTON,
            data: {
              title: t('undo'),
              iconComponent: Icon.Undo,
              onClick: (event: React.MouseEvent) => {
                undo();
              },
            }
          },
          {
            type: MenuItemType.BUTTON,
            data: {
              title: t('redo'),
              iconComponent: Icon.Redo,
              onClick: (event: React.MouseEvent) => {
                redo();
              },
            }
          },              
        ],
      },
    ];

    return configSections;
  }


  return getConfigSections;
}


export default useConfig;