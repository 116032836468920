import { BsCapslockFill }             from "react-icons/bs";
import { BsCardText }                 from "react-icons/bs";
import { BsShift }                    from "react-icons/bs";
import { BsShiftFill }                from "react-icons/bs";
import { FaBrain }                    from "react-icons/fa";
import { FaCamera }                   from "react-icons/fa";
import { FaRegEdit }                  from "react-icons/fa";
import { FaRegImage }                 from "react-icons/fa6";
import { GrGallery }                  from "react-icons/gr";
import { MdCloudUpload }              from "react-icons/md";

import { MdDelete }                   from "react-icons/md";
import { MdSettings }                 from "react-icons/md";
import { FaLongArrowAltLeft }         from "react-icons/fa";
import { FaLongArrowAltRight }        from "react-icons/fa";
import { PiAlignTopSimpleFill }       from "react-icons/pi";
import { PiAlignBottomSimpleFill }    from "react-icons/pi";
import { PiAlignLeftSimpleFill }      from "react-icons/pi";
import { PiAlignRightSimpleFill }     from "react-icons/pi";
import { BsThreeDots }                from "react-icons/bs";
import { MdTableRows }                from "react-icons/md";
import { IoGridSharp }                from "react-icons/io5";
import { BsThreeDotsVertical }        from "react-icons/bs";
import { FaEyeSlash }                 from "react-icons/fa";
import { BsDot }                      from "react-icons/bs";
import { TbFileOrientation }          from 'react-icons/tb';
import { BsTable }                    from 'react-icons/bs';
import { MdCheckBoxOutlineBlank }     from "react-icons/md";

import { PiAlignCenterVerticalSimpleFill }   from "react-icons/pi";
import { PiAlignCenterHorizontalSimpleFill } from "react-icons/pi";

import { MdFormatBold }           from "react-icons/md";
import { MdFormatItalic }         from "react-icons/md";
import { MdFormatAlignLeft }      from "react-icons/md";
import { MdFormatAlignCenter }    from "react-icons/md";
import { MdFormatAlignRight }     from "react-icons/md";
import { MdFormatListBulleted }   from "react-icons/md";
import { MdFormatListNumbered }   from "react-icons/md";
import { MdFormatIndentDecrease } from "react-icons/md";
import { MdFormatIndentIncrease } from "react-icons/md";

import { FaPlus }   from "react-icons/fa";
import { MdRemove } from "react-icons/md";
import { MdAdd }    from "react-icons/md";

import { MdFormatColorFill } from "react-icons/md";
import { MdFormatColorText } from "react-icons/md";
import { FaIcons }           from "react-icons/fa";
import { MdClose }           from "react-icons/md";
import { MdExitToApp }       from "react-icons/md";
import { MdUndo }            from "react-icons/md";
import { MdRedo }            from "react-icons/md";

import { TbPhotoEdit }       from "react-icons/tb";
import { TbPhoto }           from "react-icons/tb";
import { TbTxt }             from "react-icons/tb";

import { MdOutlineMinimize } from "react-icons/md";
import { MdOutlineMaximize } from "react-icons/md";

import { AiFillEye }          from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';

import { MdTitle }                     from "react-icons/md";
import { MdOutlineFormatStrikethrough } from "react-icons/md";

import { IoChevronDownCircleOutline } from "react-icons/io5";
import { IoChevronUpCircleOutline }   from "react-icons/io5";
import { IoChevronUp }   from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";

import { FaLock }     from 'react-icons/fa';
import { FaLockOpen } from 'react-icons/fa';
import { FaUnlock }   from "react-icons/fa";

import { TbBoxAlignTopLeft }     from "react-icons/tb";
import { TbBoxAlignTopRight }    from "react-icons/tb";
import { TbBoxAlignBottomLeft }  from "react-icons/tb";
import { TbBoxAlignBottomRight } from "react-icons/tb";

import { FaExpandArrowsAlt } from "react-icons/fa";

import { PiFilePdf }            from "react-icons/pi";
import { MdOutlineNewReleases } from "react-icons/md";
import { TbPackageImport } from "react-icons/tb";

import { MdOutlineSave }   from "react-icons/md";
import { MdOutlineSaveAs } from "react-icons/md";
import { MdOutlineAddBox } from "react-icons/md";

import { FaBrush }   from 'react-icons/fa6';
import { ImArrowUp } from 'react-icons/im';

import { PiDressFill   } from 'react-icons/pi';
import { FaLayerGroup }  from 'react-icons/fa';

import { TbResize } from "react-icons/tb";
import { MdTextSnippet } from 'react-icons/md';

import { BsBoxArrowUp } from "react-icons/bs";
import { TbArrowUp } from "react-icons/tb";

import { TbCircle }    from "react-icons/tb";
import { TbRectangle } from "react-icons/tb";
import { LuRectangleVertical } from "react-icons/lu";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdHistory } from "react-icons/md";
import { BiSolidDockTop } from "react-icons/bi";
import { HiOutlineViewColumns } from "react-icons/hi2";

import { CgBorderStyleDashed } from "react-icons/cg";
import { CgBorderStyleDotted } from "react-icons/cg";
import { CgBorderStyleSolid } from "react-icons/cg";
import { TbSection } from "react-icons/tb";

import { FiArrowUpCircle } from "react-icons/fi";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowDownCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { BiDuplicate } from "react-icons/bi";
import { MdControlPointDuplicate } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import { MdContentPasteGo } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaPaintbrush } from "react-icons/fa6";
import { FaDev } from "react-icons/fa";

import { AiOutlineUser }  from 'react-icons/ai';
import { IoColorPalette } from "react-icons/io5";
import { PiPiggyBank } from "react-icons/pi";
import { MdAccountCircle } from "react-icons/md";
import { IoIosCheckmark } from "react-icons/io";

import { FaPhoneVolume } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";

import { IoMdDocument }   from "react-icons/io";
import { FaUsers }        from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { FaFileContract } from "react-icons/fa6";
import { MdPrivacyTip }   from "react-icons/md";

import { BiExit } from "react-icons/bi";
import { IoBug } from "react-icons/io5";
import { FaBugs } from "react-icons/fa6";
import { RiMenuUnfold3Fill } from "react-icons/ri";
import { RiMenuUnfold4Fill } from "react-icons/ri";
import { FaHeart } from "react-icons/fa6";
import { RiEmotionHappyLine } from "react-icons/ri";
import { MdAccessAlarms } from "react-icons/md";

import { LuClipboardPaste } from "react-icons/lu";
import { LuClipboardCopy } from "react-icons/lu";

import { IoPersonSharp } from "react-icons/io5";
import { RiRobot2Fill } from "react-icons/ri";
import { TbGrid3X3 } from "react-icons/tb";
import { IoIosGrid } from "react-icons/io";

import { MdOutlineTextRotationNone as Label} from "react-icons/md";

import { ImPrevious } from "react-icons/im";
import { ImNext } from "react-icons/im";
import { ImStop } from "react-icons/im";

import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { BiSolidMoviePlay } from "react-icons/bi";

import { BsChatTextFill } from "react-icons/bs";
import { IoMdArrowRoundBack as Back } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { MdMoreHoriz } from "react-icons/md";

import { BsAndroid2 } from "react-icons/bs";
import { IoLogoApple } from "react-icons/io5";
import { FaWindows } from "react-icons/fa";

import { MdDarkMode } from "react-icons/md";
import { MdLightMode } from "react-icons/md";
import { PiFileXls } from "react-icons/pi";


export const Icon = {
  More: MdMoreHoriz,
  Movie: BiSolidMoviePlay,
  Callout: BsChatTextFill,
  
  Clock: {
    Alarm: MdAccessAlarms
  }, 
  Face: {
    Happy: RiEmotionHappyLine,
  },
  Heart: FaHeart,
  Menu: {
    Show: RiMenuUnfold3Fill,
    Hide: RiMenuUnfold4Fill,
  },
  Move: {
    Up: FiArrowUpCircle,
    Left: FiArrowLeftCircle,
    Down: FiArrowDownCircle,
    Right: FiArrowRightCircle,
  },

  Bug: IoBug,
  Bugs: FaBugs,
  
  Create: FaPlus,
  CreateAbove: FaPlus,
  CreateBelowe: FaPlus,
  Add: FaPlus,
  AddBox: MdOutlineAddBox,

  Undo: MdUndo,
  Redo: MdRedo,
  
  Duplicate: MdControlPointDuplicate,

  InsertLeft: FaLongArrowAltLeft,
  InsertRight: FaLongArrowAltRight,
  Edit: {
    Doc: FaRegEdit,
    Plain: MdEdit,
  },
  Delete: MdDelete,
  Remove: MdDelete,
  Exit: MdExitToApp,
  Close: MdClose,
  Back,

  Brain: FaBrain,
  Smart: FaBrain,

  Show: AiFillEye,
  Hide: AiFillEyeInvisible,

  Capslock: BsCapslockFill,
  Shift: BsShiftFill,
  Unshift: BsShift,

  Copy: MdContentCopy, 
  Paste: MdContentPasteGo,
  Default: FaStar, 

  Marker: {
    Toolbar: FaIcons, 
    Type: {
      Text: TbTxt,
      Icon: TbPhoto,
      IconAndText: TbPhotoEdit,
    },
  },
  Text: BsCardText,
  Label: Label,
  Image: FaRegImage,

  Upload: {
    Disk: MdCloudUpload,
    Camera: FaCamera,
    Gallery: GrGallery,
  },

  ImageGallery: GrGallery,

  Grid: IoDocumentTextOutline, // or BsFillGrid3X3GapFill
  Changelog: MdHistory,

  Settings: MdSettings,
  Export: TbPackageImport,
  File: {
    XLS: PiFileXls,
  },
  Layout: {
    Column: MdTableRows,
    Rows:   IoGridSharp,
  },

  Align: {
    SpaceBetween: {
      Horizontal: BsThreeDots,
      Vertical: BsThreeDotsVertical,
    },

    To: {
      Top:    PiAlignTopSimpleFill,
      Bottom: PiAlignBottomSimpleFill,
      Center: {
        Horizontal: PiAlignCenterHorizontalSimpleFill,
        Vertical:   PiAlignCenterVerticalSimpleFill,
      },
      Left: {
        Horizontal: PiAlignLeftSimpleFill,
      },
      Right: {
        Horizontal: PiAlignRightSimpleFill,
      }
    }
  },

  Page: TbFileOrientation,
  DocHeader: BiSolidDockTop,
  Table: BsTable,
  Column: LuRectangleVertical,
  Columns: HiOutlineViewColumns,
  Cell: MdCheckBoxOutlineBlank,

  OnPage: {
    Visibility: {
      None: FaEyeSlash,
      FirstPage: BsDot,
      All: BsThreeDots,
    },
  },

  Plus: MdAdd,
  Minus: MdRemove,

  FormatText: {
    Bold: MdFormatBold,
    Italic: MdFormatItalic,

    Align: {
      Left: MdFormatAlignLeft,
      Center: MdFormatAlignCenter,
      Right: MdFormatAlignRight,
    },

    List: {
      Bullet: MdFormatListBulleted,
      Numbered: MdFormatListNumbered,
    },

    Indent: {
      Decrease: MdFormatIndentDecrease,
      Increase: MdFormatIndentIncrease,
    },

    Color: {
      Text: MdFormatColorText,
      Fill: MdFormatColorFill,
    }
  },

  Window: {
    Minimize: MdOutlineMinimize,
    Maximize: MdOutlineMaximize,
  },

  Title: {
    Visible: MdTitle,
    Hidden: MdOutlineFormatStrikethrough,
  },

  Expand: {
    Circle: {
      More: IoChevronDownCircleOutline,
      Less: IoChevronUpCircleOutline,
    },
    More: IoChevronDown,
    Less: IoChevronUp,
  },

  Sort: {
    Asc: TiArrowSortedUp,
    Desc: TiArrowSortedDown,
  },

  Lock: {
    Close: FaLock,
    Open: FaLockOpen,
    Unlock: FaUnlock,
  },

  Corner: {
    TopLeft: TbBoxAlignTopLeft,
    TopRight: TbBoxAlignTopRight,
    BottomLeft: TbBoxAlignBottomLeft,
    BottomRight: TbBoxAlignBottomRight,
  },

  ImageView: {
    AutoFit: FaExpandArrowsAlt,
    Overlayer: {
      Shadow: IoIosGrid,
      Lines: TbGrid3X3,
      None: AiFillEyeInvisible,
    }
  },

  PDF: {
    Preview: PiFilePdf,
    Release: MdOutlineNewReleases,
  },

  Save: MdOutlineSave,
  SaveAs: MdOutlineSaveAs,

  Arrow: {
    Bold: ImArrowUp,
  },

  Brush: FaBrush,
  PaintBrush: FaPaintbrush,
  Styles: PiDressFill,
  Layers: FaLayerGroup,
  ViewSize: TbResize,
  TextBox: MdTextSnippet,

  Widget: {
    Shape: {
      Arrow: TbArrowUp,
      ArrowText: BsBoxArrowUp,
      TextBox: BsCardText,
      Circle: TbCircle,
      Rectangle: TbRectangle,
    },
    Action: {
      Copy: LuClipboardCopy,
      Paste: LuClipboardPaste,
      Duplicate: BiDuplicate,
    }
  },

  Border: {
    Style: {
      None: AiFillEyeInvisible,
      Dashed: CgBorderStyleDashed,
      Dotted: CgBorderStyleDotted,
      Solid: CgBorderStyleSolid,
    }
  },
  Section: TbSection,

  Dev: FaDev,

  User: AiOutlineUser,
  Theme: IoColorPalette,
  Billing: PiPiggyBank,
  Logout: BiExit,
  Account: MdAccountCircle,

  Tick: {
    Small: IoIosCheckmark,
  },

  contact: {
    Phone: FaPhoneVolume,
    Twitter: FaXTwitter,
    Email: MdEmail,
    WhatsApp: IoLogoWhatsapp,
  },

  Documents: IoMdDocument,
  Members: FaUsers,
  Pricing: FaShoppingCart,
  
  legal: {
    TermsOfService: FaFileContract,
    PrivacyPolicy: MdPrivacyTip,
  },

  Demo: FaStar,

  Automation: {
    Automatic: RiRobot2Fill,
    Manual: IoPersonSharp,
  },

  Idx: {
    Prev: ImPrevious,
    Next: ImNext,
    Stop: ImStop,
  },

  OS: {
    Android: BsAndroid2, 
    MacOSX: IoLogoApple,
    Windows: FaWindows,
  },

  ViewMode: {
    Dark: MdDarkMode,
    Light: MdLightMode,
  }

}



export default Icon;