import { createContext } from 'react';
import { ExcelExportAssetsRepo } from 'app/arch/editor-instruction/excel-exporter';


export interface ContextProps {
  assetsRepo: ExcelExportAssetsRepo | null
};


const Context = createContext<ContextProps>({
  assetsRepo: null,
});


export default Context;