import jtl from "tools/jtl";
import Logger from "libs/debug";
import Template          from "./states/template";
import { TemplateTools } from "./states/template";
import { TemplateTypes } from "./states/template";
import { HistoryTypes } from "../editor-instruction/document/states/history-state";
import { LexicalTools } from "../tools";
import Content, { ContentTools } from "../editor-instruction/document/states/persistent/content";
import Releaselogs, { ReleaselogsTools, ReleaselogsTypes } from "../editor-instruction/document/states/persistent/releaselogs";


type ContentStatesNames = keyof(HistoryTypes.Record);

// Remaining 
// Section/section less?
//
// headerMarkers:       HeaderMarkersState.State,
// metaData:            MetaDataState.State,
// releaselogs:         ReleaselogsState.State,
// check widget styles
//  #TODO COPY WIDGET STYLES

// Done separately
  // 'headerFields',
  // 'content',
  // 'releaselogs',

const DIRECT_COPY_STATES: ContentStatesNames[] = [
  'contentView',
  'editorImageSettings',
  'headerMeta',
  'releaselogsView',
  'repoMarkers',
  'viewsCommon',
];


export class DocTemplates {
  private _template: Template;
  
  constructor() {
    const logger = Logger.getDocTemplates();
    logger.debug("DocumentTemplate construct");
    this._template = new Template();
  }

  get template() { return this._template; }

  reset() {
    this._template.reset();
  }

  loadTemplates(reposTemplates: TemplateTypes.BackendTemplates) {
    this._template.setTemplates(reposTemplates);
  }

  applyTemplate(
    repoId: TemplateTypes.RepoId,
    content: HistoryTypes.Record
  ): HistoryTypes.Record {
    const logger = TemplateTools.getLogger();
    
    const templatePresent = this.template.hasTemplate(repoId);
    if ( ! templatePresent ) {
      logger.debug(`No template for repo: ${repoId}`);
      return content;
    }

    const template = this.template.getTemplate(repoId);
  
    this.__copyDirectStates(content, template);
    this.__copyHeaderFieldsState(content, template);
    this.__copyContentState(content, template);
    this.__copyReleaselogsState(content, template);
  
    return content;
  }

  private __copyDirectStates(
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    DIRECT_COPY_STATES.forEach(stateName => {
      this.__copyState(
        stateName,
        content,
        template
      )
    });
  }

  private __copyHeaderFieldsState(
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    // Copy header fields
    this.__copyState(
      'headerFields',
      content,
      template
    );

    // Clean up TextEditor content
    const headerFields = content.headerFields;
    const cells = headerFields.fields.cells;
   
    const keys = Object.keys(cells);
    keys.forEach((cellAddr) => {
      const cell = cells[cellAddr];
      cell.editorState = LexicalTools.getEditorInitStateSerial();
    });
  }

  private __copyContentState(
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    this.__copyState(
      'content',
      content,
      template
    );

    // Clean up content
    const contentState = new Content();
    contentState.state = content.content;

    const sectionsAddrs = contentState.getSectionsAddrs();
    if (sectionsAddrs.length < 1) {
      console.warn(`Can't copy content state from doc template`);
      console.warn(`No secitions to copy`);
      return;
    }

    sectionsAddrs.slice(1).forEach((sectionAddr) => {
      console.log(`delete ${ContentTools.getSectionKey(sectionAddr)}`);
      contentState.deleteSection(sectionAddr);
    });

    const sectionAddr = sectionsAddrs[0];
    const rowsAddrs = contentState.getRowsAddrs(sectionAddr);

    if (rowsAddrs.length < 1) {
      console.warn(`Can't copy content state from doc template`);
      console.warn(`No rows to copy`);
      return;
    }

    const rowsSlice = (
      rowsAddrs.length >= 2 ?
      2 :
      1
    );

    rowsAddrs.slice(rowsSlice).forEach((rowAddr) => {
      contentState.deleteRow(rowAddr);
    });


    const cellsAddrs = contentState.getCells(sectionAddr);
    Object.keys(cellsAddrs).forEach(cellKey => {
      const cellAddr = ContentTools.cellKeyToCellAddr(cellKey);
      contentState.resetCell(cellAddr);
    });
    
    content.content = contentState.state;
  }


  private __copyReleaselogsState(
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    this.__copyState(
      'releaselogs',
      content,
      template
    );

    //
    // Delete previous releaselogs
    //
    const releaselogs = new Releaselogs();
    releaselogs.state = content.releaselogs;

    const releaselogActive = releaselogs.getReleaselogActive();
    const releaseAddrs = releaselogs.getReleaselogsAddrs();
    releaseAddrs.forEach(releaseAddr => {
      const isActive = ReleaselogsTools.compareReleaselogAddr(releaseAddr, releaselogActive);
      if (isActive) {
        return;
      }
      releaselogs.deleteReleaselog(releaseAddr);
    });
    

    //
    // Now clean up releaslog changelog
    // 
    const changelogs = releaselogs.getReleaselogsChangelogs();
    const releaselogKey = ReleaselogsTools.getReleaselogKey(releaselogActive);  
    const changelog = changelogs[releaselogKey];

    const {cells, columns, rows} = changelog;

    //
    // Delete added rows
    //
    const rowsAddrsToRemove = rows.addrs.slice(0, -2);
    rowsAddrsToRemove.forEach(rowAddr => {
      releaselogs.deleteRow(rowAddr);
    });

    //
    // Cleanup changlog text cells
    //
    const rowsAddrsToKeep = rows.addrs.slice(-2);
    rowsAddrsToKeep.forEach(rowAddr => {
      columns.addrs.forEach(colAddr => {
        const cellAddr = {
          ...rowAddr,
          ...colAddr,
        };

        const columnProps = releaselogs.getColumnProps(colAddr);
        if (columnProps.type === ReleaselogsTypes.ColumnType.TEXT) {
          const editorState = LexicalTools.getEditorInitStateSerial();
          releaselogs.cellText_writeEditorState(cellAddr, editorState);
        }
      })
    });

    //
    // Remove releaselog description
    //
    releaselogs.updateReleaselogInfoDescription(
      releaselogActive,
      { text: null}
    );

    content.releaselogs = releaselogs.state;
  }

  private __copyState(
    stateName: ContentStatesNames,
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    const logger = TemplateTools.getLogger();
    logger.debug(`Copying template state to content: ${stateName}`);

    const state = template[stateName];
    const stateCopy = jtl.object.copy(state);

    content[stateName] = stateCopy;
  }
}
