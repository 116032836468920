import { settings } from "app/configs";
import useAppLogin from "app/ui/hooks/app/use-app-login";


const useUserGuestLogin = () => {
  const appLogin = useAppLogin();

  const run = () => {
    const {
      email, 
      password,
    } = settings.demo.user;
    
    appLogin(email, password);
  }

  return run;
}

export default useUserGuestLogin;