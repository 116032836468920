import CellImagsRepo from "./cell-images-repo/cell-images-repo";
import CellMarkersRepo from "./cell-markers-repo";


class AssetsRepo {
  private _cellImages: CellImagsRepo;
  private _cellMarkers: CellMarkersRepo;

  constructor() {
    this._cellImages = new CellImagsRepo();
    this._cellMarkers = new CellMarkersRepo();
  }

  get cellImages() { return this._cellImages; }
  get cellMarkers() { return this._cellMarkers; }
}

export default AssetsRepo;