import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { ExcelExportAssetsRepo } from 'app/arch/editor-instruction/excel-exporter';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';

import { configureHook } from '../../hooks/use-excel-export';
import ExcelExportContextComponent from './excel-export-context';
import CellImagesComponent from './cell-images';
import CellMarkersComponent from './cell-markers';

import * as Types from '../../types';

import { RowWrapper } from './styles';
import { SectionWrapper } from './styles';
import { SectionsWrapper } from './styles';


interface Props {
}


export const ExcelExportRenderComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const docState = useDocState();
  const content = docState.content;
  const [visible, setVisible] = useState(false);
  const assetsRepoRef = useRef<ExcelExportAssetsRepo | null>(null);
  const onDoneRef = useRef<Types.OnDoneFn | null>(null);


  useEffect(() => {
    if ( ! visible ) {
      return;
    }

    if ( ! onDoneRef.current ) {
      const msg = `OnDone funcition is null`;
      throw new Error(msg);
    }

    const onDone = onDoneRef.current;
    onDone();
    onDoneRef.current = null;
  }, [visible]);


  useEffect(() => {
    configureHook({
      setAssetsRepo: (repo: ExcelExportAssetsRepo) => {
        assetsRepoRef.current = repo;
      },
      showImagesRender: (onDone: Types.OnDoneFn) => { 
        if (onDoneRef.current) {
          const msg = `OnDone funcition should be null`;
          throw new Error(msg);
        }
        onDoneRef.current = onDone;

        setVisible(true); 
      },
      hideImagesRender: () => { 
        setVisible(false); 
      },
    });
  });


  const renderSections = () => {
    const sectionsAddrs = content.getSectionsAddrs();
    const Sections = sectionsAddrs.map((sectionAddr) => renderSection(sectionAddr));

    return (
      <SectionsWrapper>
        { Sections }
      </SectionsWrapper>
    );
  }

  const renderSection = (sectionAddr: ContentTypes.SectionAddr) => {
    const rows = content.getSectionRows(sectionAddr);
    const Rows = rows.addrs.map((rowAddr) => renderRow(rowAddr));
    const sectionKey = ContentTools.getSectionKey(sectionAddr);

    return (
      <SectionWrapper key={sectionKey}>
        { Rows }
      </SectionWrapper>
    );
  }

  const renderRow = (rowAddr: ContentTypes.RowAddr) => {
    const columnsAddrs = content.getColumnsAddrs();

    const Cells = columnsAddrs.map((columnAddr, idx) => {
      const cellAddr: ContentTypes.CellAddr = {
        ...rowAddr,
        columnId: columnAddr.columnId
      };
  
      return renderCell(cellAddr);
    });

    const rowKey = ContentTools.getRowKey(rowAddr);

    return (
      <RowWrapper key={rowKey}>
        { Cells }
      </RowWrapper>
    );
  } 

  const renderCell = (cellAddr: ContentTypes.CellAddr) => {
    type RenderCell =  (
      cellAddr: ContentTypes.CellAddr,
    ) => any; 

    const columnType = content.getColumnType(cellAddr);
    const renderFnsMap: {[key in ContentTypes.ColumnType]: RenderCell } = {
      [ContentTypes.ColumnType.INDEX  ]: renderCellIndex,
      [ContentTypes.ColumnType.TEXT   ]: renderCellText,
      [ContentTypes.ColumnType.IMAGES ]: renderCellImages,
      [ContentTypes.ColumnType.MARKERS]: renderCellMarkers,
    }

    const renderCellFn = renderFnsMap[columnType];
    const cell = renderCellFn(cellAddr);
    const cellKey = ContentTools.getCellKey(cellAddr);

    return (
      <React.Fragment key={cellKey}>
        { cell }
      </React.Fragment>
    );
  }

  const renderCellIndex = (cellAddr: ContentTypes.CellAddr) => {
    return "";
  }

  const renderCellText = (cellAddr: ContentTypes.CellAddr) => {
    return "";
  }

  const renderCellImages = (cellAddr: ContentTypes.CellAddr) => {
    return (
      <CellImagesComponent cellAddr={cellAddr} />
    );
  }

  const renderCellMarkers = (cellAddr: ContentTypes.CellAddr) => {
    return (
      <CellMarkersComponent cellAddr={cellAddr} />
    );
  }

  if ( ! visible ) {
    return null;
  }

  if ( assetsRepoRef.current === null) {
    return null;
  }

  return (
    <ExcelExportContextComponent assetsRepo={assetsRepoRef.current}>
      { renderSections() }
    </ExcelExportContextComponent>
  );
}
