import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useAssetsRepo from '../../hook/use-assets-repo';

import ImageIdxComponent from './image-idx';
import ImageBorderComponent from './image-border';
import WidgetsComponent from './widgets';

import { MainWrapper } from './styles';
import { Content } from './styles';
import { Scaler } from './styles';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
}


export const ImageComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
  } = props;

  const assetsRepo = useAssetsRepo();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ( ! wrapperRef.current ) {
      return;
    }

    if ( ! assetsRepo ) {
      console.warn(`Assets repo not ready`);
      return;
    }

    const element = wrapperRef.current;
    assetsRepo.cellImages.addImage(imageAddr, element);

    return () => {
      assetsRepo.cellImages.removeImage(imageAddr);
    }
  }, []);


  return (
    <MainWrapper>
      <Content ref={wrapperRef}>
        <Scaler>
          <ImageIdxComponent imageAddr={imageAddr} >
            <ImageBorderComponent imageAddr={imageAddr}>
              <WidgetsComponent imageAddr={imageAddr} />
            </ImageBorderComponent>
          </ImageIdxComponent>
        </Scaler>
      </Content>
    </MainWrapper>
  );
}

