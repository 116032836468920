import * as ContentTypes from '../../persistent/content/types';
import * as ContentTools from '../../persistent/content/tools';
import * as Types    from './types';
import * as Defaults from './defaults';


//----------------------------
// State
//

export type State = {
  column: {
    selected: ContentTypes.ColumnAddr | null,
  },

  section: {
    selected: ContentTypes.SectionAddr | null,
  },
  
  row: {
    selected: ContentTypes.RowAddr | null,
  },

  cell: {
    selected: ContentTypes.CellAddr | null,
  },

  cellImages: {
    selectedImage: ContentTypes.ImageAddr | null,
    uploadingImages: Types.CellImagesUploadingImages,
  }

  cellMarkers: {
    selectedMarker: ContentTypes.MarkerAddr | null,
  },

  panels: {
    assets: Types.PanelAssets,
    properties: Types.PanelProperties,
  },

};


//----------------------------
// Getters
//

/**
 * 
 * Column
 * 
 */

export const getColumnSelected = (state: State) => state.column.selected;

export const isColumnSelected  = (
  state: State, 
  columnAddr: ContentTypes.ColumnAddr
) => {
  const columnSelected = state.column.selected;
  const selected = (
    columnSelected !== null &&
    ContentTools.compareColumnAddr(columnAddr, columnSelected)
  );

  return selected;
}


/**
 * 
 * Section
 * 
 */

export const getSectionSelected = (state: State) => state.section.selected;

export const isSectionSelected  = (
  state: State, 
  sectionAddr: ContentTypes.SectionAddr
) => {
  const sectionSelected = state.section.selected;
  const selected = (
    sectionSelected !== null &&
    ContentTools.compareSectionAddr(sectionAddr, sectionSelected)
  );

  return selected;
}


/**
 * 
 * Row
 * 
 */
export const getRowSelected = (state: State) => state.row.selected;

export const isRowSelected  = (
  state: State, 
  rowAddr: ContentTypes.RowAddr
) => {
  const rowSelected = state.row.selected;
  const selected = (
    rowSelected !== null &&
    ContentTools.compareRowAddr(rowAddr, rowSelected)
  );

  return selected;
}


/**
 * 
 * Cell
 * 
 */
export const getCellSelected = (state: State) => state.cell.selected;

export const isCellSelected  = (
  state: State, 
  cellAddr: ContentTypes.CellAddr
) => {
  const cellSelected = state.cell.selected;
  const selected = (
    cellSelected !== null &&
    ContentTools.compareCellAddr(cellAddr, cellSelected)
  );

  return selected;
}

export const getCellImages = (state: State) => state.cellImages;

export const cellImages_countImagesUploading = (
  state: State,
  cellAddr: ContentTypes.CellAddr
) => {
  const uploadingImgs = getCellImages(state).uploadingImages;
  
  const cellKey = ContentTools.getCellKey(cellAddr);
  if (cellKey in uploadingImgs) {
    return uploadingImgs[cellKey];
  }
  else {
    return 0;
  }
}

export const cellImages_getImageSelected = (state: State) => state.cellImages.selectedImage;

export const getCellMarkers = (state: State) => state.cellMarkers;

export const getPanelAssets     = (state: State) => state.panels.assets;
export const getPanelProperties = (state: State) => state.panels.properties;


//----------------------------
// Create initial state
//

export const createInitialState = (): State => {
  const state: State = {
    column: {
      selected: null,
    },
    
    section: {
      selected: null,
    },

    row: {
      selected: null,
    },

    cell: {
      selected: null,
    },

    cellImages: {
      selectedImage: null,
      uploadingImages: {},
    },

    cellMarkers: {
      selectedMarker: null,
    },

    panels: {
      assets: Defaults.getPanelAssets(),
      properties: Defaults.getPanelProperties(),
    },

  }

  return state;
}
